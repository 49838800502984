<template>
  <div>
    <b-form-group
      label=""
      label-for="select-editors"
      label-cols="0"
      label-cols-sm="0"
      label-cols-md="0"
      class="m-0 p-0"
    >
      <!-- <b-input-group class="mb-1">
        <b-form-input
          v-model="input_search"
          type="search"
          size="sm"
          placeholder="Buscar Criterios de Evaluación Micro"
        >
        </b-form-input>
        <b-input-group-append>
          <b-button
            :disabled="!input_search"
            @click="input_search = ''"
            size="sm"
            >Limpiar</b-button
          >
        </b-input-group-append>
      </b-input-group> -->
      <b-form-select
        v-model="evaluation_criterias_ids"
        :select-size="10"
        size="sm"
        multiple
        class="select-form"
      >
        <b-form-select-option-group
          v-for="item in fillEvaluationsCriteriasList"
          :key="item.id"
          :label="item.name"
        >
          <b-select-option
            class="select-option-micro"
            v-for="ecm in item.options"
            :value="ecm.id"
            :key="ecm.id"
            :title="ecm.name"
          >
            {{ item.order }}.{{ ecm.order }} {{ ecm.full_sentence }}
          </b-select-option>
        </b-form-select-option-group>
      </b-form-select>
    </b-form-group>
    <div class="col" style="text-align: right">
      <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "EvaluationCriteriasMicro",
  props: {
    EvaluationCriterias: {
      type: Array,
    },
    Section: {
      type: Object,
    },
    Options: {
      type: Array,
    },
  },
  data() {
    return {
      input_search: "",
      evaluation_criterias_ids: this.EvaluationCriterias,
    };
  },
  computed: {
    fillEvaluationsCriteriasList: function () {
      return this.Options;
      // .filter((item) => {
      //   return (
      //     this.$filtered(String(item.name), this.input_search) ||
      //     item.options.find((x) => {
      //       this.evaluation_criterias_ids.includes(x.id);
      //     })
      //   );
      // });
      // .sort(function (a, b) {
      //   return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      // });
    },
  },
  methods: {
    save() {
      this.$emit("created", this.evaluation_criterias_ids, this.Section);
    },
  },
  created() {
    // this.EvaluationCriterias.forEach((element) => {
    //   if (!isNaN(element.id)) this.evaluation_criterias_ids.push(element.id);
    // });
  },
};
</script>

<style scoped>
.select-form {
  display: block !important;
}
.select-option-macro,
.select-option-micro {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding: 0 0.25em;
  margin-left: 10px;
}
.select-option-micro {
  text-indent: -16px;
}
</style>